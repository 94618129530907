<template>
  <v-navigation-drawer
    v-model="drawer"
    width="80%"
    height="100%"
    class="rounded-tl-lg"
    temporary
    right
    app
  >
    <v-container>
      <v-row>
        <!-- 닫기 -->
        <v-col cols="12">
          <v-btn color="black" icon @click="closeNav()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <!-- 리스트 -->
        <v-col v-for="(i, index) in navList" :key="index" cols="12">
          <v-btn text plain :ripple="false" height="24" @click="clickNav(i)">
            {{ i.title }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    navList: [
      { title: '법인 재무 컨설팅', to: '#financial-consulting' },
      { title: '세무서비스 점검', to: '#check-tax-service' },
      { title: 'Cash Design', to: '#cash-design' },
      { title: 'Contact Us', to: '#contact-us' }
    ]
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.layout.isNav
      },
      set(val) {
        this.$store.commit('toggleNav', val)
      }
    }
  },
  methods: {
    clickNav(val) {
      let element = null

      if (val.to === '#financial-consulting') {
        element = document.getElementById('financial-consulting')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#check-tax-service') {
        element = document.getElementById('check-tax-service')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#cash-design') {
        element = document.getElementById('cash-design')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      if (val.to === '#contact-us') {
        element = document.getElementById('contact-us')
        element.scrollIntoView({ behavior: 'smooth' })
      }

      this.closeNav()
    },
    closeNav() {
      this.$store.commit('toggleNav', false)
    }
  }
}
</script>
